import vegetariano1 from "../assets/images/COMIDA/vegetariana1.jpg";
import vegetariano2 from "../assets/images/COMIDA/vegetariana2.jpg";
import vegetariano3 from "../assets/images/COMIDA/vegetariana3.jpg";

export const vegetariano = {
  description:
    "<p>La cocina vegetariana es uno de los fuertes de nuestra cocina, basada en platos elaborados pero sencillos y siempre con la mejor materia prima que se encuentra, en la mayoría de los casos, proveniente de nuestra propia huerta, en la que cultivamos, dependiendo de la temporada, tomates, pimientos, zanahorias, lechugas, cebollas, ajos, berenjenas, patatas, calabacines, rúcula, rabanitos, alcachofas, alubias, habas, fresas, etc.</p><p>Todo ello puede formar parte de cualquiera de los platos vegetarianos que hacemos como son:</p><ul><li>Rosco de verduras</li><li>Rosco de verduras</li><li>Souflé de calabacín, calabaza o berenjena</li><li>Empanada de espinacas o acelgas con queso</li><li>Pimientos rellenos</li><li>Berenjenas con queso</li><li>Calabacín relleno</li><li>Pastel de verduras</li><li>Revuelto de verduras</li><li>Alcachofas rebozadas con salsa de tomate</li><li>Ensalada de temporada</li><li>Tortitas de la casa con harina de garbanzos</li></ul><p>Así que, si eres vegetariano, ya sabes que no tendrás ningún problema para disfrutar en nuestro restaurante de menús vegetarianos con productos de calidad. Y si no lo eres, a buen seguro disfrutarás asimismo de ellos.</p>",
  title: "Menu vegetariano",
  image: vegetariano1,
  imagesArray: [vegetariano1, vegetariano2, vegetariano3],
};
