import tradicional1 from "../assets/images/COMIDA/tradicional1.jpg";
import tradicional2 from "../assets/images/COMIDA/tradicional2.jpg";
import tradicional3 from "../assets/images/COMIDA/tradicional3.jpg";

export const tradicional = {
  description:
    "<p>No venga a nuestro restaurante “Los Jerónimos” buscando comer un buen cocido o una buena paella (que si nos ponemos, por supuesto también lo hacemos), pues no entra por lo general en nuestros menús.</p><p>Cómo platos estrellas de la cocina de Castilla y León, podemos destacar:</p><p>- El asado de lechazo (cordero lechal), de raza castellana pura, nacido y criado en La Tuda, hecho en horno de leña y en cazuela de barro de Pereruela, con leña de encina y llevando como únicos ingredientes cordero, agua y sal. Se acompaña, como es tradicional, con ensalada de lechuga. En la simplicidad está el éxito</p><p>- La chuleta de ternera sayaguesa, quizás menos conocida que la alistana pero de tanta calidad como aquella, de ganaderías extensivas de la zona, hecha a la plancha, se acompaña generalmente con patatas fritas... en buen aceite de oliva</p><p>Además para los que prefieren un pescado, os ofrecemos una lubina a la plancha con salsa. Esto sí que no es km 0. Pero está muy buena.</p><p>Entre los postres “de la tierra”, arroz con leche, tarta de manzana y la irresistible tarta de chocolate.</p>",
  title: "Menu tradicional",
  image: tradicional1,
  imagesArray: [tradicional1, tradicional2, tradicional3],
};
