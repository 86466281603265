import peruano1 from "../assets/images/COMIDA/peruano1.jpg";
import peruano2 from "../assets/images/COMIDA/peruano2.jpg";
import peruano3 from "../assets/images/COMIDA/peruano3.jpg";

export const peruano = {
  description:
    "<p>Siendo Celia, propietaria y cocinera de Los Jerónimos, de raíces peruanas, la gran cocina de este país andino brilla con luz propia en nuestro restaurante, encontrándonos intercalados en el menú los platos más tradicionales de su Perú natal, a saber:</p><ul><li><b>Aperitivo:</b> Pisco Sour</li><li><b>Entrantes o primeros:</b>Cebiche, Tiradito, Papa rellena, Causa limeña, Pimiento relleno, Papa a la Huancaína, Tamales,...</li><li><b>Segundos:</b> Lomo saltado, Ají de gallina, Anticucho de carne de ternera...</li><li><b>Postres:</b> Tarta de 3 leches, Bavarois de lúcuma, Arroz con leche con mazamorra morada, merengue de lúcuma...</li></ul><p>Llevamos unos años realizando en los meses de febrero y marzo, unas “Jornadas de Cocina Peruana”, en las cuales, durante los fines de semana, se realizan distintos menús.</p><p>Cada fin de semana, hay un único menú y sólo ese.</p><p>En todos los menús, se ofrece un Pisco Sour (coctel hecho a base de aguardiente de uva peruano, limón, clara de huevo, canela y mucho hielo), para empezar a entrar en ambiente. Posteriormente, se ofrecen dos entrantes y un segundo, más un postre.</p><p>Cada año, se anuncian en la página web, en carteles en Zamora y en redes sociales y van teniendo mucho éxito. Solemos hacer 7 u 8 fines de semana.</p><p>Además, fuera de dichas Jornadas, se pueden realizar menús peruanos, encargando con antelación y para un mínimo de 8 personas. Podría ser cualquiera de los menús de las Jornadas o con alguna variación de éstos.</p>",
  title: "Menu peruano",
  image: peruano1,
  imagesArray: [peruano1,peruano2, peruano3],
};
