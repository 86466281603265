import * as React from "react";
import { Head } from "../../components/Header/head";
import { Navbar } from "../../components/Navbar/navbar";
import { restaurante } from "../../assets/restaurante";
import "../../styles/restaurante.css";
import Footer from "../../components/Footer/footer";
import ModalCarousel from "../../components/ModalCarousel/ModalCarousel";

import { peruano } from "../../assets/peruano";
import { navigate } from "gatsby-link";
import { tradicional } from "../../assets/tradicional";
import { vegetariano } from "../../assets/vegetariano";
import menu_precio from "../../assets/images/precio_menu.jpg";
import vinos1 from "../../assets/images/vinos1.jpg";
import vinos2 from "../../assets/images/vinos2.jpg";
import { Link } from "gatsby";
import { SSRProvider } from "@react-aria/ssr";
const Restaurante = () => {
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Restaurante" />
      <Navbar />
      <section>
        <h2> Restaurante</h2>
        <p>
          Abierto, al igual que la Posada, en el año 2000, nuestro restaurante
          es fiel a un estilo propio y original, basado en una cocina elaborada
          pero sin florituras, donde los productos locales, junto con los de
          nuestra propia huerta ecológica, son aderezados con cariño y la mejor
          mano de nuestra cocinera.{" "}
        </p>
        <p>
          {" "}
          No encontrarás “carta” sino que nuestra propuesta se basa en un menú
          propio y por encargo, lo que hace que venir a comer a “Los Jerónimos”
          sea una experiencia diferente, eso sí, procurando que todo el mundo
          quede satisfecho.
        </p>
        <p>
          {" "}
          En nuestra cocina, una parte de los platos son a base de verduras u
          hortalizas que provienen de nuestro propio huerto, lechazo nacido y
          criado en La Tuda y cocinado en horno de leña de encina con cazuela de
          barro de Pereruela, carne de terneras criadas en Sayago y, por
          supuesto, nuestro pan ecológico de elaboración propia, hecho con
          harinas zamoranas ecológicas.
        </p>
        <p>
          {" "}
          Muchos productos básicos como la harina, las legumbres, el aceite, el
          azúcar, la miel, los huevos, el café son, o bien de producción
          ecológica o de comercio justo o de cercanía (o las 3 cosas a la vez,
          mejor que mejor). Entendemos que así debe ser: aunque conlleva un
          gasto algo mayor, apostamos por los productos cultivados de manera
          tradicional en defensa de una soberanía alimentaria cada vez más
          amenazada en un mundo globalizado y manejado por las grandes
          multinacionales de la alimentación, a los que nos resistimos a
          favorecer. Sabemos que lo que damos es sano y bueno, beneficia a
          agricultores y ganaderos locales de las zonas cercanas, con nombres y
          apellidos.
        </p>
        <p>
          {" "}
          Como ejemplo y en resumen, pudiendo ofrecer una mermelada casera, no
          vamos a dar una industrial envasada en un mini recipiente de plástico.
        </p>
      </section>
      <section className="images-section">
        <div className="images-wrapper">
          {restaurante.map((imagen, index) => {
            return (
              <ModalCarousel
                key={index}
                images={restaurante}
                portada={restaurante[index].url}
              />
            );
          })}
        </div>
      </section>
      <section className="seccion-menus">
        <h2> Precios de nuestros menús y carta de vinos</h2>
        <div className="menu-wrapper">
          <div className="menu">
            <img src={menu_precio} />
          </div>
          <div className="menu">
            <img src={vinos2} />
          </div>
          <div className="menu">
            <img src={vinos1} />
          </div>
        </div>
      </section>
      <section className="menus-section">
        <div className="menu-type-wrapper">
          <Link to={`/detalle?${peruano.title}`} state={{ data: peruano }}>
            <div className="tipo-menu">
              <div className="imagen-tipo">
                <img src={peruano.image} />
              </div>
              <div className="tipo-titulo">Peruano</div>
            </div>
          </Link>
          <Link
            to={`/detalle?${tradicional.title}`}
            state={{ data: tradicional }}
          >
            <div className="tipo-menu">
              <div className="imagen-tipo">
                <img src={tradicional.image} />
              </div>
              <div className="tipo-titulo">Tradicional</div>
            </div>
          </Link>
          <Link
            to={`/detalle?${vegetariano.title}`}
            state={{ data: vegetariano }}
          >
            <div className="tipo-menu">
              <div className="imagen-tipo">
                <img src={vegetariano.image} />
              </div>
              <div className="tipo-titulo">Vegetariano</div>
            </div>
          </Link>
        </div>
      </section>
      <Footer slim />
    </SSRProvider>
  );
};

export default Restaurante;
