import restaurante1 from "./images/RESTAURANTE/restaurante1.jpg";
import restaurante2 from "./images/RESTAURANTE/restaurante2.jpg";
import restaurante3 from "./images/RESTAURANTE/restaurante3.jpg";
import restaurante4 from "./images/RESTAURANTE/restaurante4.jpg";
import restaurante5 from "./images/RESTAURANTE/restaurante5.jpg";
import restaurante6 from "./images/RESTAURANTE/restaurante6.jpg";

export const restaurante = [
  {
    url: restaurante1,
  },
  {
    url: restaurante2,
  },
  {
    url: restaurante3,
  },
  {
    url: restaurante4,
  },
  {
    url: restaurante5,
  },
  {
    url: restaurante6,
  },
];
